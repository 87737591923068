<template>
  <div class="content-2 up-1" v-scrollanimate>
    <picture class="image-2">
      <source srcset="~@/assets/c_img_1.webp" type="image/webp">
      <source srcset="~@/assets/c_img_1.jpg" type="image/jpeg">
      <img src="~@/assets/c_img_1.jpg" alt="Traffic interchange" />
    </picture>
    <div class="t-1">
      <div class="h6 challenges supt">Challenge #2</div>
      <h3 class="h3">Parking search</h3>
    </div>
    <div class="p-1">Car culture assumes that everyone should be able to get anywhere with their car, but where are they supposed to leave it? Should cities keep destroying their centers to make space for more roads and car parks?</div>
  </div>
</template>

<script>
export default {
  name: 'ChallengeContent2'
}
</script>

<style lang="scss" scoped>
.supt {
  padding-bottom: 12px;
}
.content-2 {
  @include grid;
  margin-bottom: 128px;
  @include laptop {
    margin-bottom: 96px;
  }
  @include tablet {
    margin-bottom: 64px;
  }
  .image-2 {
    grid-column: 1 / 15;
    grid-row: 1 / 3;
    @include tablet {
      grid-column: 1 / 11;
    }
    @include phone {
      grid-column: 1 / 7;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      @include phone {
        height: 66.7vw;
        object-fit: cover;
      }
    }
  }
  .t-1 {
    grid-column: 2 / 6;
    grid-row: 3 / 4;
    margin-top: 128px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      margin-top: 64px;
    }
  }
  .p-1 {
    grid-column: 8 / 13;
    grid-row: 3 / 4;
    margin-top: 128px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      grid-column: 2 / 8;
      grid-row: 4 / 5;
      margin-top: 64px;
    }
    @include phone {
      grid-column: 2 / 6;
      margin-top: 32px;
    }
  }
}

</style>
