<template>
  <div class="content-1 up-1" v-scrollanimate>
    <h2 class="h4 t-1">Transportation infrastructures around the world are overloaded, costing cities billions annually in loss of productivity, in pollution, and in wasted land.</h2>
    <hr class="line">
    <div class="t-2">
      <div class="h6 challenges supt">Challenge #1</div>
      <h3 class="h3">Road Congestion</h3>
    </div>
    <div class="p-2">City centers worldwide are flooded with ever more private cars, where traffic jams condemn commuters to spending hours on the road, costing the economy billions in wasted productivity. Perhaps it’s time to reconsider the basics of road infrastructure?</div>
  </div>
</template>

<script>
export default {
  name: 'ChallengeContent1'
}
</script>

<style lang="scss" scoped>
.supt {
  padding-bottom: 12px;
}
.content-1 {
  @include grid;
  margin-top: 128px;
  margin-bottom: 128px;
  @include laptop {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  @include tablet {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .t-1 {
    grid-column: 8 / 13;
    grid-row: 1 / 2;
    margin-bottom: 128px;
    @include laptop {
      margin-bottom: 96px;
    }
    @include tablet {
      grid-column: 2 / 8;
      margin-bottom: 64px;
    }
    @include phone {
      grid-column: 2 / 6;

    }
  }
  .line {
    grid-column: 2 / 14;
    grid-row: 2 / 3;
    margin-bottom: 48px;
    @include tablet {
      grid-column: 2 / 10;
    }
    @include phone {
      margin-bottom: 26px;
      grid-column: 2 / 6;
    }
  }
  .t-2 {
    grid-column: 2 / 6;
    grid-row: 3 / 4;
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .p-2 {
    grid-column: 8 / 13;
    grid-row: 3 / 4;
    @include tablet {
      grid-column: 2 / 8;
      grid-row: 4 / 5;
      margin-top: 64px;
    }
    @include phone {
      grid-column: 2 / 6;
      margin-top: 32px;
    }
  }
}

</style>
