<template>
  <div class="content-4 up-1" v-scrollanimate>
    <hr class="line">
    <div class="t-2">
      <div class="h6 challenges supt">Challenge #5</div>
      <h3 class="h3">Diminished budgets for infrastructure</h3>
    </div>
    <div class="p-2">The need for transportation infrastructure is rising, but public budgets are diminishing. Private ventures may sometimes construct urban infrastructure in exchange for exclusive rights to operate it but how could solving all the above problems be translated into a business interest?</div>
  </div>
</template>

<script>
export default {
  name: 'ChallengeContent4'
}
</script>

<style lang="scss" scoped>
.supt {
  padding-bottom: 12px;
}
.content-4 {
  @include grid;
  margin-bottom: 128px;
  @include laptop {
    margin-bottom: 96px;
  }
  @include tablet {
    margin-bottom: 64px;
  }
  .line {
    grid-column: 2 / 14;
    grid-row: 1 / 2;
    margin-bottom: 48px;
    @include tablet {
      grid-column: 2 / 10;
    }
    @include phone {
      margin-bottom: 26px;
      grid-column: 2 / 6;
    }
  }
  .t-2 {
    grid-column: 2 / 6;
    grid-row: 2 / 3;
  }
  .p-2 {
    grid-column: 8 / 13;
    grid-row: 2 / 3;
    @include tablet {
      grid-column: 2 / 8;
      grid-row: 3 / 4;
      margin-top: 64px;
    }
    @include phone {
      grid-column: 2 / 6;
      margin-top: 32px;
    }
  }
}

</style>
