<template>
  <div class="challenge page">
    <ChallengeHero/>
    <ChallengeContent1/>
    <ChallengeContent2/>
    <ChallengeContent3/>
    <ChallengeContent4/>
    <ChallengeCallToAction/>
    <NavFooter/>
  </div>
</template>

<script>
import ChallengeHero from '../components/ChallengeHero'
import ChallengeContent1 from '../components/ChallengeContent1'
import ChallengeContent2 from '../components/ChallengeContent2'
import ChallengeContent3 from '../components/ChallengeContent3'
import ChallengeContent4 from '../components/ChallengeContent4'
import ChallengeCallToAction from '../components/ChallengeCallToAction'
import NavFooter from '../components/NavFooter'

export default {
  name: 'Challenge',
  components: {
    ChallengeHero,
    ChallengeContent1,
    ChallengeContent2,
    ChallengeContent3,
    ChallengeContent4,
    ChallengeCallToAction,
    NavFooter
  }
}
</script>

<style lang="scss" scoped>

</style>
