import { render, staticRenderFns } from "./ChallengeHero.vue?vue&type=template&id=2cb54482&scoped=true&"
import script from "./ChallengeHero.vue?vue&type=script&lang=js&"
export * from "./ChallengeHero.vue?vue&type=script&lang=js&"
import style0 from "./ChallengeHero.vue?vue&type=style&index=0&id=2cb54482&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cb54482",
  null
  
)

export default component.exports