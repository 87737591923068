<template>
  <div class="content-3 up-1" v-scrollanimate>
    <picture class="image-3">
      <source srcset="~@/assets/c_img_2.webp" type="image/webp">
      <source srcset="~@/assets/c_img_2.jpg" type="image/jpeg">
      <img src="~@/assets/c_img_2.jpg" alt="Land shortage"/>
    </picture>
    <div class="t-1">
      <div class="h6 challenges supt">Challenge #3</div>
      <h3 class="h3">Energy loss, gas emissions, and human stress</h3>
    </div>
    <div class="p-1">Urban traffic jams mean idle cars that burn fuel for nothing, emissions that pollute the air and stressed citizens from a life of sitting in traffic and searching for parking. Does transportation have to take such a toll on society and the environment?</div>
    <hr class="line">
    <div class="t-2">
      <div class="h6 challenges supt">Challenge #4</div>
      <h3 class="h3">Land shortage and urban sprawl</h3>
    </div>
    <div class="p-2">Making cities car-friendly means taking land that is scarce and expensive and using it for car infrastructure. This pushes buildings and services further to the periphery, which requires constructing even more infrastructure to reach them. What if transportation infrastructure could be built without taking up additional land?</div>
  </div>
</template>

<script>
export default {
  name: 'ChallengeContent3'
}
</script>

<style lang="scss" scoped>
.supt {
  padding-bottom: 12px;
}
.content-3 {
  @include grid;
  margin-bottom: 128px;
  @include laptop {
    margin-bottom: 96px;
  }
  @include tablet {
    margin-bottom: 64px;
  }
  .image-3 {
    grid-column: 1 / 15;
    grid-row: 1 / 3;
    @include tablet {
      grid-column: 1 / 11;
    }
    @include phone {
      grid-column: 1 / 7;
    }
    img {
      width: 100%;
      vertical-align: bottom;
      @include phone {
        height: 66.7vw;
        object-fit: cover;
      }
    }
  }
  .t-1 {
    grid-column: 2 / 6;
    grid-row: 3 / 4;
    margin-top: 128px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      margin-top: 64px;
    }
  }
  .p-1 {
    grid-column: 8 / 13;
    grid-row: 3 / 4;
    margin-top: 128px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      grid-column: 2 / 8;
      grid-row: 4 / 5;
      margin-top: 64px;
    }
    @include phone {
      grid-column: 2 / 6;
      margin-top: 32px;
    }
  }
  .line {
    grid-column: 2 / 14;
    grid-row: 4 / 5;
    margin-top: 128px;
    margin-bottom: 48px;
    @include laptop {
      margin-top: 96px;
    }
    @include tablet {
      grid-column: 2 / 10;
      margin-top: 64px;
      grid-row: 5 / 6;
    }
    @include phone {
      margin-bottom: 26px;
      grid-column: 2 / 6;
    }
  }
  .t-2 {
    grid-column: 2 / 6;
    grid-row: 5 / 6;
    @include tablet {
      grid-row: 6 / 7;
    }
    @include phone {
      grid-column: 2 / 6;
    }
  }
  .p-2 {
    grid-column: 8 / 13;
    grid-row: 5 / 6;
    @include tablet {
      grid-column: 2 / 8;
      grid-row: 7 / 8;
      margin-top: 64px;
    }
    @include phone {
      grid-column: 2 / 6;
      margin-top: 32px;
    }
  }
}

</style>
